import React from 'react';
import './styles.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import CalculatorPage from './components/CalculatorPage';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Register from './components/Register';
import { AuthProvider, useAuth } from './components/AuthContext';
import { LanguageProvider } from './i18n/LanguageContext';
import Profile from './components/Profile';

const PrivateRoute = ({ children }) => {
    const { user, loading } = useAuth();
    
    if (loading) {
        return <div>Loading...</div>;
    }
    
    return user ? children : <Navigate to="/login" />;
};

function AppContent() {
    const { user } = useAuth();

    return (
        <div>
            <Navbar />
            <Routes>
                <Route path="/login" element={!user ? <Login /> : <Navigate to="/calculator" />} />
                <Route path="/register" element={!user ? <Register /> : <Navigate to="/calculator" />} />
                <Route path="/" element={<LandingPage />} />
                <Route path="/calculator" element={
                    <PrivateRoute>
                        <CalculatorPage />
                    </PrivateRoute>
                } />
                <Route path="/profile" element={
                    <PrivateRoute>
                        <Profile />
                    </PrivateRoute>
                } />
            </Routes>
        </div>
    );
}

function App() {
    return (
        <AuthProvider>
            <LanguageProvider>
                <AppContent />
            </LanguageProvider>
        </AuthProvider>
    );
}

export default App;
