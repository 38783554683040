import React, { useState, useEffect } from 'react';
import { useLanguage } from '../i18n/LanguageContext';
import Calculator from './Calculator';

const CalculatorPage = () => {
    const { t } = useLanguage();
    const [isOverlayOpen, setOverlayOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleOverlay = () => {
        setOverlayOpen(!isOverlayOpen);
    };

    return (
        <div className="App">
            <h1>{t('calculator.title')}</h1>
            <Calculator />
            {isMobile ? (
                <div className="calculator-button-container">
                    <button className="about-button" onClick={toggleOverlay}>{t('calculator.about')}</button>
                </div>
            ) : (
                <button className="about-button" onClick={toggleOverlay}>{t('calculator.about')}</button>
            )}
            {isOverlayOpen && (
                <div className="overlay">
                    <div className="overlay-content">
                        <p>{t('calculator.aboutContent.description')}</p>
                        <p>{t('calculator.aboutContent.evaluation')}</p>
                        <ul>
                            <li>{t('calculator.aboutContent.states.healthy')}</li>
                            <li>{t('calculator.aboutContent.states.capitalLoss')}</li>
                            <li>{t('calculator.aboutContent.states.overindebtedness')}</li>
                        </ul>
                        <button className="close-button" onClick={toggleOverlay}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CalculatorPage;
