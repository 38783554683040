import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../i18n/LanguageContext';
import './LandingPage.css';

const LandingPage = () => {
    const navigate = useNavigate();
    const { t } = useLanguage();

    const goToCalculator = () => {
        navigate('/calculator');
    };

    return (
        <div className="landing-page">
            <div className="landing-content">
                <h1>{t('landing.welcome')}</h1>
                <p>{t('landing.description')}</p>
                <button className="get-started-button" onClick={goToCalculator}>
                    {t('landing.getStarted')}
                </button>
            </div>
        </div>
    );
};

export default LandingPage;
