export const translations = {
    en: {
        navbar: {
            home: 'Home',
            calculator: 'Calculator',
            profile: 'My Profile',
            login: 'Login',
            register: 'Register',
            logout: 'Logout'
        },
        landing: {
            welcome: 'Welcome to the 725 Calculator la zone',
            description: 'Assess the financial stability of your company based on Swiss legal standards.',
            getStarted: 'Get Started'
        },
        calculator: {
            title: 'Capital Loss and Overindebtedness Calculator',
            assetsOperating: 'Total Assets (Operating Value)',
            assetsLiquidation: 'Total Assets (Liquidation Value)',
            liabilities: 'Total Liabilities',
            nominalShareCapital: 'Nominal Share Capital',
            legalReserves: 'Legal Reserves',
            financialStatus: 'Financial Status:',
            healthy: 'Healthy',
            capitalLoss: 'Capital Loss',
            overindebtedness: 'Overindebtedness',
            about: 'About',
            aboutContent: {
                description: 'This online calculator evaluates the financial status of a company based on the Swiss Code of Obligations (CO), specifically Articles 725 and 725a, which govern financial distress and insolvency.',
                evaluation: 'By inputting key financial figures such as total assets, liabilities, nominal share capital, and legal reserves, the calculator determines if the company is:',
                states: {
                    healthy: 'Financially Healthy',
                    capitalLoss: 'In a state of Capital Loss (Article 725 CO)',
                    overindebtedness: 'Facing Overindebtedness (Article 725a CO)'
                }
            }
        }
    },
    fr: {
        navbar: {
            home: 'Accueil',
            calculator: 'Calculateur',
            profile: 'Mon Profil',
            login: 'Connexion',
            register: 'Inscription',
            logout: 'Déconnexion'
        },
        landing: {
            welcome: 'Bienvenue sur le Calculateur 725',
            description: 'Évaluez la stabilité financière de votre entreprise selon les normes légales suisses.',
            getStarted: 'Commencer'
        },
        calculator: {
            title: 'Calculateur de Perte de Capital et Surendettement',
            assetsOperating: 'Total des Actifs (Valeur d\'exploitation)',
            assetsLiquidation: 'Total des Actifs (Valeur de liquidation)',
            liabilities: 'Total des Passifs',
            nominalShareCapital: 'Capital-actions nominal',
            legalReserves: 'Réserves légales',
            financialStatus: 'Situation financière:',
            healthy: 'Saine',
            capitalLoss: 'Perte de Capital',
            overindebtedness: 'Surendettement',
            about: 'À propos',
            aboutContent: {
                description: 'Ce calculateur en ligne évalue la situation financière d\'une entreprise selon le Code des obligations suisse (CO), en particulier les articles 725 et 725a, qui régissent les difficultés financières et l\'insolvabilité.',
                evaluation: 'En saisissant les chiffres financiers clés tels que le total des actifs, les passifs, le capital-actions nominal et les réserves légales, le calculateur détermine si l\'entreprise est :',
                states: {
                    healthy: 'Financièrement saine',
                    capitalLoss: 'En perte de capital (Article 725 CO)',
                    overindebtedness: 'En situation de surendettement (Article 725a CO)'
                }
            }
        }
    },
    de: {
        navbar: {
            home: 'Startseite',
            calculator: 'Rechner',
            profile: 'Mein Profil',
            login: 'Anmelden',
            register: 'Registrieren',
            logout: 'Abmelden'
        },
        landing: {
            welcome: 'Willkommen beim 725 Rechner',
            description: 'Bewerten Sie die finanzielle Stabilität Ihres Unternehmens nach Schweizer Rechtsstandards.',
            getStarted: 'Loslegen'
        },
        calculator: {
            title: 'Kapitalverlust- und Überschuldungsrechner',
            assetsOperating: 'Gesamtvermögen (Betriebswert)',
            assetsLiquidation: 'Gesamtvermögen (Liquidationswert)',
            liabilities: 'Gesamtverbindlichkeiten',
            nominalShareCapital: 'Nominalaktienkapital',
            legalReserves: 'Gesetzliche Reserven',
            financialStatus: 'Finanzstatus:',
            healthy: 'Gesund',
            capitalLoss: 'Kapitalverlust',
            overindebtedness: 'Überschuldung',
            about: 'Über',
            aboutContent: {
                description: 'Dieser Online-Rechner bewertet die finanzielle Situation eines Unternehmens nach dem Schweizerischen Obligationenrecht (OR), insbesondere nach den Artikeln 725 und 725a, die finanzielle Notlagen und Insolvenz regeln.',
                evaluation: 'Durch Eingabe wichtiger Finanzkennzahlen wie Gesamtvermögen, Verbindlichkeiten, Nominalaktienkapital und gesetzliche Reserven ermittelt der Rechner, ob das Unternehmen:',
                states: {
                    healthy: 'Finanziell gesund ist',
                    capitalLoss: 'Sich im Kapitalverlust befindet (Artikel 725 OR)',
                    overindebtedness: 'Von Überschuldung betroffen ist (Artikel 725a OR)'
                }
            }
        }
    },
    it: {
        navbar: {
            home: 'Home',
            calculator: 'Calcolatore',
            profile: 'Profilo',
            login: 'Accesso',
            register: 'Registrazione',
            logout: 'Esci'
        },
        landing: {
            welcome: 'Benvenuto nel Calcolatore 725',
            description: 'Valuta la stabilità finanziaria della tua azienda secondo gli standard legali svizzeri.',
            getStarted: 'Inizia'
        },
        calculator: {
            title: 'Calcolatore di Perdita di Capitale e Sovraindebitamento',
            assetsOperating: 'Totale Attività (Valore Operativo)',
            assetsLiquidation: 'Totale Attività (Valore di Liquidazione)',
            liabilities: 'Totale Passività',
            nominalShareCapital: 'Capitale Azionario Nominale',
            legalReserves: 'Riserve Legali',
            financialStatus: 'Stato Finanziario:',
            healthy: 'Sano',
            capitalLoss: 'Perdita di Capitale',
            overindebtedness: 'Sovraindebitamento',
            about: 'Info',
            aboutContent: {
                description: 'Questo calcolatore online valuta la situazione finanziaria di un\'azienda secondo il Codice delle obbligazioni svizzero (CO), in particolare gli articoli 725 e 725a, che regolano le difficoltà finanziarie e l\'insolvenza.',
                evaluation: 'Inserendo i principali dati finanziari come totale attività, passività, capitale azionario nominale e riserve legali, il calcolatore determina se l\'azienda è:',
                states: {
                    healthy: 'Finanziariamente sana',
                    capitalLoss: 'In perdita di capitale (Articolo 725 CO)',
                    overindebtedness: 'In situazione di sovraindebitamento (Articolo 725a CO)'
                }
            }
        }
    }
};
