import React, { useState, useMemo } from 'react';
import { useLanguage } from '../i18n/LanguageContext';

const Calculator = () => {
    const { t } = useLanguage();
    const [assetsOperating, setAssetsOperating] = useState('');
    const [assetsLiquidation, setAssetsLiquidation] = useState('');
    const [liabilities, setLiabilities] = useState('');
    const [nominalShareCapital, setNominalShareCapital] = useState('');
    const [legalReserves, setLegalReserves] = useState('');

    const capitalLossThreshold = useMemo(() => {
        const nominal = Number(nominalShareCapital) || 0;
        const reserves = Number(legalReserves) || 0;
        return 0.5 * (nominal + reserves);
    }, [nominalShareCapital, legalReserves]);

    const netEquity = useMemo(() => {
        const operating = Number(assetsOperating) || 0;
        const totalLiabilities = Number(liabilities) || 0;
        return operating - totalLiabilities;
    }, [assetsOperating, liabilities]);

    const calculateResult = useMemo(() => {
        const operating = Number(assetsOperating) || 0;
        const liquidation = Number(assetsLiquidation) || 0;
        const totalLiabilities = Number(liabilities) || 0;

        const overindebtedOperating = totalLiabilities > operating;
        const overindebtedLiquidation = totalLiabilities > liquidation;
        const isOverindebted = overindebtedOperating && overindebtedLiquidation;
        const isInCapitalLoss = netEquity < capitalLossThreshold;
        const isHealthy = netEquity >= capitalLossThreshold && !isOverindebted;

        if (isOverindebted) {
            return t('calculator.overindebtedness');
        } else if (isInCapitalLoss) {
            return t('calculator.capitalLoss');
        } else if (isHealthy) {
            return t('calculator.healthy');
        } else {
            return ""; // Return empty string if all inputs are empty or invalid
        }
    }, [liabilities, assetsOperating, assetsLiquidation, netEquity, capitalLossThreshold]);

    // Check if all fields are filled
    const allFieldsFilled = assetsOperating !== '' && 
                            assetsLiquidation !== '' && 
                            liabilities !== '' && 
                            nominalShareCapital !== '' && 
                            legalReserves !== '';

    return (
        <div className="calculator">
            <label>{t('calculator.assetsOperating')}</label>
            <input
                type="number"
                onChange={(e) => setAssetsOperating(e.target.value)}
                value={assetsOperating}
                placeholder="0"
                min="0"
            />
            <label>{t('calculator.assetsLiquidation')}</label>
            <input
                type="number"
                onChange={(e) => setAssetsLiquidation(e.target.value)}
                value={assetsLiquidation}
                placeholder="0"
                min="0"
            />
            <label>{t('calculator.liabilities')}</label>
            <input
                type="number"
                onChange={(e) => setLiabilities(e.target.value)}
                value={liabilities}
                placeholder="0"
                min="0"
            />
            <label>{t('calculator.nominalShareCapital')}</label>
            <input
                type="number"
                onChange={(e) => setNominalShareCapital(e.target.value)}
                value={nominalShareCapital}
                placeholder="0"
                min="0"
            />
            <label>{t('calculator.legalReserves')}</label>
            <input
                type="number"
                onChange={(e) => setLegalReserves(e.target.value)}
                value={legalReserves}
                placeholder="0"
                min="0"
            />
            {allFieldsFilled && (
                <h2>
                    Financial Status:<br /> 
                    {calculateResult}
                    {calculateResult === "Healthy" && (
                        <span className="emoji-animate" key="healthy">&nbsp;🎉</span>
                    )}
                    {calculateResult === "Overindebtedness" && (
                        <span className="emoji-animate" key="overindebtedness">&nbsp;😭</span>
                    )}
                    {calculateResult === "Capital Loss" && (
                        <span className="emoji-animate" key="capital-loss">&nbsp;😨</span>
                    )}
                </h2>
            )}
            <div style={{ fontSize: 'smaller' }}>
                <p style={{ margin: '0' }}>Capital Loss Threshold: {capitalLossThreshold}</p>
                <p style={{ margin: '0' }}>Net Equity: {netEquity}</p>
            </div>
        </div>
    );
};

export default Calculator;
