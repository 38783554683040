import React, { useState } from 'react';
import { auth } from '../firebase';
import { updateProfile, updateEmail, updatePassword } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import './Auth.css';

const Profile = () => {
    const [fullName, setFullName] = useState(auth.currentUser?.displayName || '');
    const [email, setEmail] = useState(auth.currentUser?.email || '');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        try {
            const updates = [];

            // Update display name if changed
            if (fullName !== auth.currentUser.displayName) {
                updates.push(
                    updateProfile(auth.currentUser, { displayName: fullName })
                        .then(() => updateDoc(doc(db, "users", auth.currentUser.uid), {
                            fullName: fullName
                        }))
                );
            }

            // Update email if changed
            if (email !== auth.currentUser.email) {
                updates.push(updateEmail(auth.currentUser, email));
            }

            // Update password if provided
            if (newPassword) {
                updates.push(updatePassword(auth.currentUser, newPassword));
            }

            await Promise.all(updates);
            setMessage('Profile updated successfully!');
            setNewPassword(''); // Clear password field after successful update
        } catch (error) {
            setError('Failed to update profile: ' + error.message);
        }
    };

    return (
        <div className="auth-container">
            <div className="auth-form">
                <h2>My Profile</h2>
                {message && <p className="success">{message}</p>}
                {error && <p className="error">{error}</p>}
                <div className="profile-header">
                    <button 
                        className="edit-button"
                        onClick={() => setIsEditing(!isEditing)}
                        title={isEditing ? "Cancel editing" : "Edit profile"}
                    >
                        Edit my profile <FontAwesomeIcon icon={faPencilAlt} />
                    </button>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <input
                            type="text"
                            placeholder="Full Name"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            disabled={!isEditing}
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={!isEditing}
                        />
                    </div>
                    {isEditing && (
                        <div className="input-group">
                            <input
                                type="password"
                                placeholder="New Password (leave empty to keep current)"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </div>
                    )}
                    {isEditing && (
                        <button type="submit">Update Profile</button>
                    )}
                </form>
            </div>
        </div>
    );
};

export default Profile;
