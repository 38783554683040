import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { useAuth } from './AuthContext';
import { useLanguage } from '../i18n/LanguageContext';
import './Navbar.css';

const Navbar = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { language, setLanguage, t } = useLanguage();

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/login');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">725 Calculator</div>
            <button className="menu-toggle" onClick={toggleMenu}>☰</button>
            <ul className={`navbar-links ${isMenuOpen ? 'active' : ''}`} onClick={() => setIsMenuOpen(false)}>
                <li><Link to="/">{t('navbar.home')}</Link></li>
                {user ? (
                    <>
                        <li><Link to="/calculator">{t('navbar.calculator')}</Link></li>
                        <li><Link to="/profile">{t('navbar.profile')}</Link></li>
                        <li><button onClick={handleLogout} className="logout-button">{t('navbar.logout')}</button></li>
                    </>
                ) : (
                    <>
                        <li><Link to="/login">{t('navbar.login')}</Link></li>
                        <li><Link to="/register">{t('navbar.register')}</Link></li>
                    </>
                )}
                <li className="language-selector">
                    <select 
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        className="language-select"
                    >
                        <option value="en">EN</option>
                        <option value="fr">FR</option>
                        <option value="de">DE</option>
                        <option value="it">IT</option>
                    </select>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
